import { useState, useCallback, useEffect, useRef } from "react";
import { Socket } from "socket.io-client";

export type RecorderState = 'recording' | 'paused' | 'stopped'
export interface WebRTCAudioRecorderControls {
  state: RecorderState;
  mediaStream: MediaStream | null;
  start: () => void;
  stop: () => void;
}

export type MediaAudioTrackConstraints = Pick<
  MediaTrackConstraints,
  | "deviceId"
  | "groupId"
  | "autoGainControl"
  | "channelCount"
  | "echoCancellation"
  | "noiseSuppression"
  | "sampleRate"
  | "sampleSize"
>;

export interface AudioRecorderOptions {
  socket: Socket;
  audioTrackConstraints?: MediaAudioTrackConstraints;
  onStart?: () => void;
  onStop?: () => void;
}

const useWebRTCRecorder: (options: AudioRecorderOptions) => WebRTCAudioRecorderControls = (
  options,
) => {
  const { socket } = options;

  const [state, setState] = useState<RecorderState>('stopped');
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  const peerRef = useRef<RTCPeerConnection | null>(null);
  const mediaStreamRef = useRef<MediaStream | null>(null);
  const onStartRef = useRef<() => void |undefined>();
  const onStopRef = useRef<() => void |undefined>();

  const setupPeer = useCallback(async (peer: RTCPeerConnection) => {
    const stream = await navigator.mediaDevices.getUserMedia({ 
      audio: options.audioTrackConstraints ?? true,
    });

    stream.getTracks().forEach(track => peer.addTrack(track, stream));
    mediaStreamRef.current = stream;
  }, [
    options.audioTrackConstraints,
  ]);

  const stop: () => void = useCallback(() => {
    console.log('stop');
    let peer = peerRef.current;

    if (!peer)
      return;

    socket.emit('rtc.close');
    peer?.close();
    peerRef.current = null;
    onStopRef.current?.();
  }, [socket]);

  const start: () => void = useCallback(async () => {
    let peer = peerRef.current;

    if (peer)
      stop();

    peer = new RTCPeerConnection();
    peerRef.current = peer;

    try {
      await setupPeer(peer);

      const offer = await peer.createOffer();
      await peer.setLocalDescription(offer);
  
      socket.emit('rtc.offer', peer.localDescription);
      onStartRef.current?.();
    } catch (error) {
      stop();
    }
  }, [socket, stop, setupPeer]);

  useEffect(() => {
    const onOffer = async (description: RTCSessionDescriptionInit) => {
      const peer = new RTCPeerConnection();
      peerRef.current = peer;
  
      try {
        await setupPeer(peer);
  
        await peer.setRemoteDescription(new RTCSessionDescription(description));
        const answer = await peer.createAnswer();
        await peer.setLocalDescription(answer);
    
        socket.emit('rtc.answer', peer.localDescription);
    
        peer.onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit('rtc.candidate', event.candidate);
          }
        };
      } catch (error) {
        onError(error as Error);
      }
    }
  
    const onAnswer = async (description: RTCSessionDescriptionInit) => {
      const peer = peerRef.current;

      if (!peer)
        return;
  
      try {
        await peer.setRemoteDescription(new RTCSessionDescription(description));

        peer.onicecandidate = (event) => {
          if (event.candidate) {
            socket.emit('rtc.candidate', event.candidate);
          }
        };
      } catch (error) {
        onError(error as Error);
      }
    }
  
    const onCandidate = async (candidate: RTCIceCandidateInit) => {
      const peer = peerRef.current;

      if (!peer)
        return;
  
      try {
        await peer.addIceCandidate(new RTCIceCandidate(candidate));
      } catch (error) {
        onError(error as Error);
      }
    }

    const onClose = async (): Promise<void> => {
      stop();
    }

    const onError = async (error: Error): Promise<void> => {
      stop();
    }

    socket.on('rtc.offer', onOffer);
    socket.on('rtc.answer', onAnswer);
    socket.on('rtc.candidate', onCandidate);
    socket.on('rtc.close', onClose);

    return () => {
      socket.off('rtc.offer', onOffer);
      socket.off('rtc.answer',onAnswer);
      socket.off('rtc.candidate', onCandidate);
      socket.off('rtc.close', onClose);
    }
  }, [socket, stop, setupPeer]);


  useEffect(() => {
    return () => {
      const peer = peerRef.current;
      const stream = mediaStreamRef.current;
      peer?.close();
      stream?.getTracks()?.forEach((track) => track.stop());
    }
  }, []);

  useEffect(() => {
    onStartRef.current = () => {
      console.log('onStart')

      setState('recording');
      setMediaStream(mediaStreamRef.current);
      options.onStart?.();
    }

    onStopRef.current = () => {
      console.log('onStop')

      mediaStreamRef.current?.getTracks()?.forEach((track) => track.stop());
      mediaStreamRef.current = null;

      setState('stopped');
      setMediaStream(mediaStreamRef.current);
      options.onStop?.();
    }
  }, [
    options,
    setState,
    setMediaStream,
  ]);

  return {
    state,
    mediaStream,
    start,
    stop,
  };
};

export default useWebRTCRecorder;