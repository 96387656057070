import { useCallback } from "react";
import Icon from "@mdi/react";
import { mdiMicrophone, mdiStop } from "@mdi/js";
import { WebRTCAudioRecorderControls } from "../../hooks/useWebRTCRecorder";
import { LiveAudioVisualizer } from "../LiveAudioVisualizer";
import './styles.css';

export interface Props {
  recorder: WebRTCAudioRecorderControls;
}

function AudioRecorder(props: Props) {
  const {
    state,
    mediaStream,
    start,
    stop,
  } = props.recorder;

  const toggle = useCallback(() => {
    if (state === 'recording') {
      stop();
    } else {
      start();
    }
  }, [state, start, stop]);

  return (
    <div className="audio-recorder">
      <div className="visualizer">
        <LiveAudioVisualizer
          mediaStream={mediaStream}
          barColor="white"
          barWidth={4}
          gap={2}
        />
      </div>
      <div className="controls">
        <div 
          className="button"
          onClick={() => toggle()}
        >
          { state !== 'recording' && <Icon path={mdiMicrophone} /> }
          { state === 'recording' && <Icon path={mdiStop} /> }
        </div>
      </div>
    </div>
  )
}

export { AudioRecorder };